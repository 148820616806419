<template>
	<div class="main1">
		<div class="icon">
			<img src="../../../assets/images/personalCenter/realname-authentication.png">
		</div>
		<div class="">
			{{$t("realnameauthsucceeded")}}
		</div>
	</div>
</template>

<script>
	export default {
		props: {},
		data(){
			return {
				
			}
		},
		methods: {
			
		}
	}
</script>

<style lang="less" scoped>
	.main1 {
		width: 100%;
		height: 650px;
		background: @recommendMusicianListBGColor;
		border-radius: 6px;
		backdrop-filter: blur(50px);
		margin-top: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: @musicLibraryFilterMenuNavTextColor;
		.icon{
			margin-bottom: 24px;
			img {
				width: 183px;
				height: 183px;
			}
		}
		.button{
			width: 172px;
			height: 38px;
			background: #3370FF;
			border-radius: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			font-weight: 600;
			color: #FFFFFF;
		}
	}
</style>